<template>
  <div class="clients page-section">
    <slot name="title"></slot>
    <div :class="['clients__ticker', substrateColor]">
      <ul class="clients__ticker__list">
        <li v-for="item in logoList1" :key="item.id">
          <img :src="require(`~/assets/img/clients/clients-${item.src}.svg`)" loading="lazy" :alt="item.alt"
            :width="item.width" height="48">
        </li>
        <li v-for="item in logoList1" :key="item.id">
          <img :src="require(`~/assets/img/clients/clients-${item.src}.svg`)" loading="lazy" :alt="item.alt"
            :width="item.width" height="48">
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ClientsSliderMixin from '~/components/Desktop/Common/mixins/ClientsSliderMixin';
export default {
  name: 'ClientsSliderDesktop',
  mixins: [ClientsSliderMixin],
  props: {
    substrateColor: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.clients {

  @include screen-up("xl") {
    max-width: 1400px;
    margin: 0 auto;
  }

  &__title {
    margin: 0 0 20px;
    font-family: 'MTS Wide', 'Arial', sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 100%;

  }

  &__ticker {
    margin: 0px auto;
    width: 1160px;
    overflow: hidden;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 120px;
      height: 100%;
      z-index: 50;
    }

    &::before {
      left: -60px;
    }

    &:after {
      right: -60px;
    }

    &.--white {
      &::before {
        background: linear-gradient(90deg, #fff, #fff 49.48%, hsla(0, 0%, 100%, 0));
      }

      &:after {
        background: linear-gradient(270deg, #fff, #fff 49.48%, hsla(0, 0%, 100%, 0));
      }
    }

    &.--grey {
      &::before {
        background: linear-gradient(90deg, #F2F3F7 0%, #F2F3F7 49.48%, rgba(255, 255, 255, 0) 100%);
      }

      &::after {
        background: linear-gradient(270deg, #F2F3F7 0%, #F2F3F7 49.48%, rgba(255, 255, 255, 0) 100%);
      }
    }

    ul {
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 0;
      width: 200%;
      animation: ticker 35s linear infinite;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;
        margin: 0 8px;
        height: 48px;
        width: auto;
        min-width: 30px;
      }
    }
  }
}

@keyframes ticker {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-2252.6px);
  }
}
</style>
