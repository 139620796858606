export default {
  data() {
    return {
      logoList1: [
        {
          src: 'bitrix',
          alt: 'Логотип bitrix',
          width: '187',
        },
        {
          src: 'lokotech',
          alt: 'Логотип ЛокоТех',
          width: '189',
        },
        {
          src: 'hh',
          alt: 'Логотип hh',
          width: '62',
        },
        {
          src: 'invitro',
          alt: 'Логотип invitro',
          width: '184',
        },
        {
          src: 'avito',
          alt: 'Логотип avito',
          width: '118',
        },
        {
          src: 'rusagro',
          alt: 'Логотип rusagro',
          width: '132',
        },
        {
          src: 'exmo',
          alt: 'Логотип эксмо',
          width: '75',
        },
        {
          src: 'mts',
          alt: 'Логотип mts',
          width: '62',
        },
        {
          src: 'samolet',
          alt: 'Логотип samolet',
          width: '181',
        },
        {
          src: 'russlimat',
          alt: 'Логотип Русклимат',
          width: '154',
        },
        {
          src: 'netology',
          alt: 'Логотип Нетология',
          width: '214',
        },
        {
          src: 'optbank',
          alt: 'Логотип optbank',
          width: '184',
        },
        {
          src: 'renessans',
          alt: 'Логотип renessans',
          width: '108',
        },
        {
          src: 'technonicol',
          alt: 'Логотип Технониколь',
          width: '195',
        },
      ],
    }
  },
}
